import React from "react";

import Layout from "components/Layout";
import SEO from "components/seo";

export default function PrivacyPolicy() {
  return (
    <Layout>
      <SEO
        keywords={[
          `contactless`,
          `payments`,
          `fintech`,
          `India`,
          `UPoS`,
          `Uvik Technologies`
        ]}
      />
      <div className="pt-24 lg:pb-32 pb-16 px-6 mt-8 page">
        <div className="max-w-6xl mx-auto">
          <h2>
            <strong>Terms and Conditions</strong>
          </h2>
          <p>Welcome to www.uvik.in!</p>
          <p>
            These terms and conditions outline the rules and regulations for the
            use of Uvik Technologies Pvt Ltd's Website, located at
            https://www.uvik.in.
          </p>
          <p>
            A User may opt for services of Uvik Technologies Pvt Ltd only if
            he/she accepts and abides by all of the Terms. Uvik may periodically
            change these Terms without notice.
          </p>
          <h3>
            <strong>Definitions</strong>
          </h3>
          <p>
            For the purpose of these Terms, the following words and phrases
            shall have the meaning assigned to them under this Article.
            &quot;Services&quot; means the service provided by Uvik to Users,
            whereby a User can (i) receive payments from their customers or
            other payers; or (ii) make payment to suppliers, beneficiaries or
            other payees, by (a) IMPS/NEFT/RTGS; or (b) credit/ debit card; or
            (c) net banking; or (d) any other mode of payment through banks,
            that may be accepted by Uvik from time to time in these Terms.
          </p>
          <h3>
            <strong>User</strong>
          </h3>
          <p>
            shall mean any person who is boarded on the Uvik platform for using
            Uvik Products, who transacts or avails of the Services through
            Android Application, to make or to receive payments to or from third
            parties. The term &quot;User&quot; shall also cover such personnel
            of any User who are authorized to avail of the Services on behalf of
            the User.
          </p>
          <h3>
            <strong>Nodal Account</strong>
          </h3>
          <p>
            Means such bank account maintained by Uvik or Uvik associated
            partners as required by the RBI Guidelines.
          </p>
          <h3>
            <strong>Payee</strong>
          </h3>
          <p>
            Means any person to whom a payment is made, using Uvik Services,
            (and the term includes a User who uses the Services to receive
            payment.
          </p>
          <h3>
            <strong>Payer</strong>
          </h3>
          <p>
            means any person who makes a payment, using Uvik Services (and the
            term includes a User who uses the Services to make payment).
          </p>
          <h3>
            <strong>Service Provider</strong>
          </h3>
          <p>
            means a bank, association, facility provider, card issuing
            institution, acquiring bank, other financial institution, card
            processor, clearing house networks whose facilities or services are
            utilized in the provision of these Services.
          </p>
          <h3>
            <strong>Transaction</strong>
          </h3>
          <p>
            means a payment instruction that results in the successful transfer
            of monies (a) from a User to a Payee; or (b) from a Payer to a User.
          </p>
          <h3>
            <strong>Transaction Amount</strong>
          </h3>
          <p>
            means the total amount payable/ receivable by a User/Payee. This
            amount shall include all taxes, charges, interest, delivery costs,
            commissions, etc.
          </p>
          <h3>
            <strong>Eligibility</strong>
          </h3>
          <p>
            The App and Services are intended solely for persons who can form
            legally binding contracts under Indian law and who are 18 years of
            age or older. Any access to or use of the App or Services by anyone
            under 18 is expressly prohibited. By using the App or Services User
            represents and warrants that he is 18 or older and is competent to
            contract. User also agrees that his Payer/ Payee is similarly
            competent to contract.
          </p>
          <p>
            A User represents and warrants that he has full power and authority
            for using the Services and is in strict compliance of all laws.
          </p>
          <p>
            These terms and conditions as accepted by User shall be read along
            with and in addition to any terms and conditions in force from time
            to time and issued by any Service Provider. They will also be read
            in conjunction with the Privacy Policy
          </p>
          <p>
            A User may use the Services and this App only if he accepts and
            abides by all of the following terms and conditions.
          </p>
          <h3>
            <strong>Registration</strong>
          </h3>
          <p>
            In order to avail the Services, User must be registered with Uvik
            and the Service Providers. User shall provide Uvik with all such
            documents as may be required.
          </p>
          <p>
            By registering with Uvik, User agrees to provide true, accurate,
            current and complete information failing which Uvik may at any time
            reject User’s registration and terminate his right to use or access
            the App and/or Services. When User registers with Uvik, a User
            Account will be created, that is associated with User’s user name,
            password and other identifying information. User shall be
            responsible for anything that happens through his User Account.
          </p>
          <h3>
            <strong>Use of Services</strong>
          </h3>
          <p>
            The Services may be used by User for making and receiving payments
            from / to third parties. To enable the User to use the Services, a
            link with login credentials will be communicated to the User on his
            mobile phone or email address and the User irrevocably and
            unconditionally accepts the sole responsibility for use,
            confidentiality and protection of said login credentials, including
            passwords, as well as for all orders and information changes entered
            into the mobile/ e mail account using such login credentials.
          </p>
          <p>
            Uvik has no obligation to verify the authenticity of the Transaction
            other than by means of verification of the User’s basic KYC
            information. The User shall at all times take all appropriate steps,
            including those as mentioned herein, to maintain the security and
            confidentiality of the information. Uvik shall not be liable for any
            mistake or misuse of the Services by either the User or by any
            person authorized by the User, or by any person gaining access to
            the Services through the User. User agrees that Uvik accepts all
            instructions originating from his Account in good faith and in a
            manner consistent with commercially reasonable security standards.
            User shall indemnify and hold Uvik harmless for direct or indirect
            losses sustained as a result of the disclosure of sensitive
            information by or through User.
          </p>
          <p>
            On receiving the necessary details from the Service Provider, Uvik
            shall initiate the required payment to Payee/User/ as instructed by
            User/ Payer.
          </p>
          <p>
            Confirmation of the Transaction performed using valid login
            credentials shall be conclusive evidence of a Transaction being
            effected. User is responsible to furnish Uvik with correct and
            current Payee information. In the event that the payment is in
            respect of a purchase of Products by the User/Payee, Uvik shall not
            be required to ensure that the purchased Products have been duly
            delivered. In the event a User chooses to complain about a
            Transaction, the same should be communicated to Uvik within 10 (Ten)
            days of the Transaction.
          </p>
          <h3>
            <strong>User Obligations</strong>
          </h3>
          <p>
            Each User shall be responsible to (i) furnish correct and accurate
            information of the Payer/ Payee as may be required, on an
            independent basis; (ii) furnish to Uvik forthwith on demand, the
            original copy/copies of proof of delivery of Products, invoices or
            other records pertaining to any Transaction; (iii) ensure that all
            licenses and registrations required by him are in full force and
            effect to enable them to carry on the business of sale/ purchase of
            Products.
          </p>
          <p>
            User shall not (i) carry out any activity, which is banned, illegal
            or immoral, (ii) use the Services in any manner or in furtherance of
            any activity, which constitutes a violation of any law or regulation
            or which may cause Uvik to be subject to investigation, prosecution
            or legal action.
          </p>
          <p>
            User undertakes and assures to Uvik that payments shall not be made/
            received in respect of any Products mentioned in the banned items
            list set out in Annexure A hereto.
          </p>
          <p>
            User shall act in compliance with all laws, rules and regulations
            and shall at all times comply with the guidelines set by Visa/Master
            Card/ acquiring banks/ Service Providers.
          </p>
          <p>
            User shall not sell, provide, exchange, or otherwise disclose to
            third parties or use themselves (other than for the purpose of
            completing a Transaction, or as specifically required by law) any
            personal information about any third party, including the account
            details and mobile number, without obtaining the prior written
            consent of such third party.
          </p>
          <p>
            User shall take all precautions as may be feasible or as may be
            directed by Uvik to ensure that there is no breach of security and
            that the integrity of the link between their systems/ site, the App
            and the payment mechanism is maintained at all times. In the event
            of any loss being caused as a result of the App being breached or as
            a consequence of the App being improper or being in violation of the
            provisions of this clause, the loss shall be to the account of the
            User and the User shall indemnify and keep indemnified Uvik and the
            Service Providers from any loss as may be caused in this regard.
          </p>
          <p>
            User shall bear and be responsible for the payment of all relevant
            taxes (including any applicable withholding taxes) as may be due.
          </p>
          <p>
            A User shall not at any time require any other user to provide him
            with any details of the accounts held by the other users with any
            Banks including, the passwords, account number, card numbers, mobile
            phone numbers and PIN which may be assigned to them by the Banks
            from time to time.
          </p>
          <p>
            A User shall use the information regarding a Payee/ Payer (including
            name, address, e-mail address, telephone numbers and other data)
            conveyed to him whist using the Services, only for the purpose of
            completing the Transaction for which it was furnished, and not to
            sell or otherwise furnish such information to others unless he has
            an independent source of such information or obtains the express
            consent of such Payee/ Payer.
          </p>
          <p>
            A User shall inform Uvik of any change in his email address, mobile
            number, address, ownership or legal status or his cessation of
            business in writing 30 working days in advance of such change.
          </p>
          <p>
            Uvik has the right to investigate and prosecute violations of any of
            the above to the fullest extent of the law.
          </p>
          <h3>
            <strong>Fees</strong>
          </h3>
          <p>
            The acquiring bank will authenticate, authorize, and process the
            payment instructions given by the Payer in respect of a Transaction
            upon fulfillment of valid criteria as set forth by the acquiring
            bank from time to time and accordingly transfer such approved
            Transaction Amount from the Payer’s bank account to the account(s)
            maintained by Uvik in accordance with the RBI.
          </p>
          <p>
            In consideration of the Services rendered by Uvik to the User, the
            User shall pay to Uvik a fee that is agreed upon in the Fee Schedule
            defined in the Merchant Agreement or proposed by Uvik over email to
            the registered email address.
          </p>
          <p>
            Uvik shall deduct its transaction fees plus service tax per
            successful Transaction, and make payment of the balance of the
            Transaction Amount to User/ Payee’s designated bank account. All
            other taxes, duties or charges shall be borne and paid by User,
            unless otherwise agreed between the Parties. Uvik reserves the right
            to alter / modify / change the discount / commission rate at its
            discretion. Uvik also reserves the right to forthwith revise the
            transaction fee payable in the event of any revision in the rates
            charges by the acquiring banks or card associations or guidelines
            issued by the RBI from time to time.
          </p>
          <p>
            It is hereby agreed and acknowledged by the Parties that the
            transaction fees charged by Uvik in respect of a Transaction that
            has been confirmed shall not be returned or repaid by Uvik to the
            User or any other person irrespective of the Transaction being
            rejected, charged back, refunded or disputed.
          </p>
          Specific Terms
          <p>
            Uvik will not be responsible for any disputes between the Users and
            the Payers/ Payees, including issues regarding prices, delivery,
            non-delivery or delay in delivery of the Products, quality of
            Products or otherwise. All such disputes will be dealt with by and
            between the User and the Payer/ Payee directly, and Uvik shall not
            be a party to such disputes.
          </p>
          <p>
            Uvik cannot and does not guarantee the timings of any payments made
            or received as such timing is dependent upon User’s bank, card
            issuing bank/ card processor (where a credit/ debit card is used),
            acquiring financial institution and other Service Providers. Uvik
            assumes no liability and shall not be held responsible for any
            delays in delivery of payment or availability of funds when using
            the Services.
          </p>
          <p>
            Uvik shall not be a party to the agreement between the User and any
            Payer/ Payee in any manner whatsoever. All contracts are directly
            between Users and their Payers/ Payees.
          </p>
          <p>
            User shall be responsible for any miscommunication or incorrect
            User/ third party/ other information that may be provided to Uvik at
            the time of enabling the Services for the User.
          </p>
          <p>
            Uvik/ Service Provider reserve the right to impose limits on the
            number of Transactions which may be charged on an individual credit
            card or other account during any time period, and reserve the right
            to refuse to make payments in respect of Transactions exceeding such
            limit. Uvik/ Service Provider also reserve the right to refuse to
            make payments in respect of Transactions by Users with a prior
            history of questionable charges.
          </p>
          <h3>
            <strong>Security</strong>
          </h3>
          <p>
            Subject to the provisions stated herein and as specified by Uvik
            from time to time, the User will not hold Uvik liable in case of any
            improper/ fraudulent/unauthorized/ duplicate/erroneous use of his
            mobile and/or the web based access. Uvik will also not be liable for
            any consequences connected with the use/ misuse of User’s mobile/ e
            mail account by any third party. If any third parties gain access to
            the Services, the User will be responsible and shall indemnify Uvik
            against any liability, costs or damages arising out of such misuse /
            use by third parties based upon or relating to such access and use,
            or otherwise.
          </p>
          <p>
            The User shall be fully liable for: (a) any unauthorised use of his
            mobile/ email account; and/or (b) all authorised Transactions on his
            mobile/email account.
          </p>
          <p>
            Without prejudice to the remedies available to Uvik and these Terms,
            Uvik shall be under no liability whatsoever to the User in respect
            of any loss or damage arising directly or indirectly out of:
            <ul>
              <li>any defect in any Products supplied to them;</li>
              <li>
                any inability of a third party to supply or deliver the required
                Products in the necessary numbers or types;
              </li>
              <li>
                the refusal of any person (including a Service Provider) to
                honour or accept a payment;
              </li>
              <li>the malfunction of any computer terminal or equipment;</li>
              <li>
                the utilization of the Services by any person other than by the
                User;
              </li>
              <li>
                any mis-statement, error or omission in any details disclosed to
                Uvik.
              </li>
            </ul>
          </p>
          <h3>
            <strong>Refunds/ Chargebacks </strong>
          </h3>
          <p>
            A Chargeback shall mean a Transaction that is uncollectible and
            returned to Service Provider/ Uvik by User/ Payee or acquiring bank
            for a refund to the concerned Payer / User due to any of the
            following reasons:
          </p>
          <p>
            (a)Any payment which the User/ Payer refuses to honour or demands a
            refund of because the Products purchased or the delivery thereof,
            was not as promised or was defective, deficient, incomplete and /or
            unsatisfactory for any reason whatsoever, or that payment for the
            same has already been made.
          </p>
          <p>
            (b)Any payments by a User/ Payer using the Services, which is
            returned by the acquiring bank for any reason whatsoever.
          </p>
          <p>
            (c)Any charge/debit arising out of any alleged hacking breach of
            security or encryption that may be utilized by Service Provider/
            Uvik/ Payee from time to time.
          </p>
          <p>(d)Any Transaction that is unsuccessful.</p>
          <p>
            As a result of the rapid nature of movements of funds, once a
            payment has been initiated through the Services, it may not be
            possible to retract the same using the Services. If User wishes to
            attempt to chargeback/ retract a payment, he must notify his bank
            and Uvik immediately and provide all required information. Uvik
            cannot however guarantee that the payment will be retracted. It may
            be possible to charged back/ retract a payment in case of a payment
            by debit/ credit card, if the instruction is given to User’s bank
            and Uvik within the prescribed time. However it would not be
            possible to do so in case of any other mode of payment.
          </p>
          <p>
            In the event of a Chargeback situation arising in case of any
            Products purchased by a User or Payer, for which payment has not yet
            been made to the Payee or User by Service Provider, the User or
            Payer shall be advised by Uvik to resolve the issue with the Payee
            or User or Service Provider within 45 days and Uvik shall then
            settle the payment in accordance with the solution agreed upon by
            the Parties.
          </p>
          <p>
            In such event, if the Payee and the Payer are unable to arrive at a
            satisfactory resolution of the problem within a period of 45 days,
            Uvik/ Service Provider shall be entitled to make a direct credit to
            the disputing Payer’s account for the disputed amount. Such a debit
            to the Payee’s account and the direct credit to the disputing
            Payer’s account shall not be disputed by the Payee in any manner
            whatsoever. In the event of the Payee and the Payer arriving at a
            settlement within the said 45 day period, Uvik/ Service Provider
            shall deal with the said monies in accordance with the terms of the
            settlement arrived at.
          </p>
          <p>
            In the event of a Chargeback situation arising in case of any
            Products purchased by a User/ Payer, for which payment has been made
            to the Payee/User / Service Provider, the User/ Payer shall be
            advised to resolve the issue with the Payee/User / Service Provider
            within 45 days and shall then settle the payment in accordance with
            the solution agreed upon by the Parties.
          </p>
          <p>
            Uvik shall not be responsible to make payments in respect of any
            Chargeback unless it has received the requisite amounts from the
            concerned acquiring bank/ Service Provider/ Payee/ User. In the
            event of the receipt by Uvik of an amount of a Chargeback from the
            acquiring bank / Service Provider, Uvik shall pass on to User/ Payer
            such amount received by them within 7 days.
          </p>
          <p>
            In the event that the Parties have agreed that User/ Payer is
            entitled to Chargeback any Transaction, Uvik may at its discretion,
            give effect to such Chargeback entitlement in any one or more of the
            following methods:
          </p>
          <p>
            (a)deduction of the relevant amount or any part thereof from any
            amounts to the Payee;
          </p>
          <p>
            (b)billing the concerned Party for the relevant amount or any part
            thereof.
          </p>
          <h3>
            <strong>Termination</strong>
          </h3>
          <p>
            If either Uvik or Service Provider suspects, on reasonable grounds,
            that a User has committed a breach of these Terms or any other
            agreement with Uvik or any act of dishonesty or fraud against Uvik/
            any Service Provider, Uvik shall be entitled to (a) suspend all
            payment under these Terms; (b) deactivate/ suspend your User Account
            and disable your password; and (c) erminate User’s access to the App
            or the Services; pending enquiries by Uvik. User may cancel his User
            Account at any time by sending Playmates an email. Please note that
            once your Account is cancelled, Uvik does not have any obligation to
            return any data or information that may reside on its servers or
            other equipment.
          </p>
          <h3>
            <strong>Indemnity</strong>
          </h3>
          <p>
            User shall keep Uvik indemnified from and against any and all
            liability (including but not limited to liabilities, judgments,
            damages, losses, claims, costs and expenses,) or any other loss that
            may occur, arising from or relating to any claim, suit or proceeding
            brought against Uvik by another User/ Service Provider/ third party
            for reasons including, but not limited to (i) delivery, non delivery
            or delay, deficiency or mistake in respect of the Products sold;
            (ii) a breach, non-performance, non compliance or inadequate
            performance by the User of any of the terms, conditions,
            representations, obligations or warranties made by him; (iii) any
            acts, errors, misrepresentations, wilful misconduct or negligence of
            the User, or his employees, subcontractors and agents in performance
            of their obligations under these Terms.
          </p>
          <p>
            The User shall comply with all such terms and conditions as Uvik or
            any Service Provider may prescribe from time to time with regard to
            the Services. All Transactions effected by or through this Website,
            shall constitute legally binding and valid transactions when done in
            adherence to and in compliance with the terms and conditions
            prescribed by Uvik or Seller/ Service Provider.
          </p>
          <h3>
            <strong>Confidentiality</strong>
          </h3>
          <p>
            User agrees not to disclose or attempt to use or personally benefit
            from any non-public information that he may learn on the App or
            through the Services. This obligation shall continue until such time
            as the non-public information has become publicly known through no
            action of your own. If you are compelled by order of a court or
            other governmental or legal body (or have notice that such an order
            is being sought) to divulge any such non-public information, you
            agree to promptly and diligently notify Uvik and cooperate fully
            with Uvik in protecting such information to the extent possible
            under applicable law.
          </p>
          <p>
            Uvik may access, preserve and disclose any of your information if
            required to do so by law, or if we believe in good faith that it is
            reasonably necessary to (i) respond to claims asserted against Uvik
            or to comply with legal process, (ii) for fraud prevention, risk
            assessment, investigation, customer support, product development and
            de-bugging purposes, or (iii) protect the rights, property or safety
            of Uvik, its Users or members of the public.
          </p>
          <h3>
            <strong>Intellectual Property Rights</strong>
          </h3>
          <p>
            The App content and the trademarks, service marks and logos
            contained therein ("Marks") are owned by or licensed to Uvik,
            subject to copyright and other intellectual property rights under
            the law and international conventions. Such App content may not be
            copied, reproduced, distributed, transmitted, broadcast, displayed,
            sold, licensed, uploaded, or otherwise exploited without the prior
            written consent of Uvik.
          </p>
          <h3>
            <strong>Privacy Policy</strong>
          </h3>
          <p>
            User understands that his access to the Website will result in the
            collection, use and storage of his information which is subject to
            Uvik's Privacy Policy. Through his access of the Website User
            consents to the collection, use and storage of such information,
            which will be held by Uvik and may be processed and stored by
            Service Providers. For an explanation of Uvik’s practices and
            policies related to the collection, use, and storage of Users’
            information, please read our Privacy Policy
          </p>
          <p>Disclaimer of Warranties:</p>
          <p>
            THE CONTENT AND FUNCTIONALITY ON THE WEBSITE IS PROVIDED WITH THE
            UNDERSTANDING THAT UVIK IS NOT HEREIN ENGAGED IN ITSELF FOR MAKING
            PAYMENTS. UVIK IS AN INTERMEDIARY BETWEEN BANKS, SERVICE PROVIDERS
            AND USERS. ALL CONTENT AND FUNCTIONALITY ON THE WEBSITE IS PROVIDED
            "AS IS," WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED,
            INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
            NON-INFRINGEMENT. PAYMAE MAKES NO WARRANTIES, EXPRESS OR IMPLIED, AS
            TO THE ACCURACY, OR ADEQUACY OF THE WEBSITE OR THAT THE SERVICES
            PROVIDED OR THE FUNCTIONALITY ON THIS WEBSITE WILL BE UNINTERRUPTED
            OR ERROR-FREE.
          </p>
          <p>
            USER HEREBY ACKNOWLEDGES THAT HIS USE OF THIS WEBSITE AND THE
            SERVICES IS AT HIS SOLE RISK. UNDER NO CIRCUMSTANCES SHALL UVIK OR
            ANY OF ITS PREDECESSORS, SUCCESSORS, PARENTS, SUBSIDIARIES,
            AFFILIATES, OFFICERS, DIRECTORS, SHAREHOLDERS, INVESTORS, EMPLOYEES,
            AGENTS, REPRESENTATIVES, ATTORNEYS AND THEIR RESPECTIVE HEIRS,
            SUCCESSORS OR ASSIGNS BE LIABLE FOR ANY DAMAGES, INCLUDING DIRECT,
            INCIDENTAL, PUNITIVE, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES
            THAT DIRECTLY OR INDIRECTLY RESULT FROM THE USE OF, OR THE INABILITY
            TO USE, THIS WEBSITE OR THESERVICES, INCLUDING FOR VIRUSES ALLEGED
            TO HAVE BEEN OBTAINED FROM THE WEBSITE, EVEN IF UVIK HAS BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES AND REGARDLESS
            OF THE THEORY OF LIABILITY.
          </p>
          <p>Limitation of Liability:</p>
          <p>
            USER ACKNOWLEDGES AND AGREES THAT, TO THE MAXIMUM EXTENT PERMITTED
            BY LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE
            WEBSITE AND SERVICES, REMAINS WITH YOU. NEITHER UVIK NOR ANY OTHER
            PARTY INVOLVED IN PROVIDING OR DELIVERING THE WEBSITE OR SERVICES
            WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR
            CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR LOSS
            OF GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE
            OR THE COST OF SUBSTITUTE SERVICES, OR IN CONNECTION WITH THESE
            TERMS, AS A RESULT OF YOUR USE OF THE WEBSITE OR SERVICES, WHETHER
            BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT
            LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT UVIK HAS
            BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED
            REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL
            PURPOSE.
          </p>
          <p>
            EXCEPT FOR UVIK OBLIGATIONS TO MAKE PAYMENTS THROUGH THE NODAL
            ACCOUNT AS ENVISAGED HEREIN, IN NO EVENT WILL UVIK'S AGGREGATE
            LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS AND
            USER’S USE OF THE WEBSITE AND SERVICES, EXCEED THE AMOUNT OF RUPEES
            1000.
          </p>
          <h3>
            <strong>General Terms</strong>
          </h3>
          <p>
            Unless otherwise set out, all payments may only be made in Indian
            Rupees.
          </p>
          <p>
            Uvik may, if required by law, change any fee or charge or institute
            new fees or charges. The User agrees to pay all fees and charges so
            levied.
          </p>
          <p>
            Uvik reserves the right to vary any of the Terms contained herein by
            posting the revised terms on Uvik’s Website without notice to the
            User.
          </p>
          <p>
            The User hereby accepts that he may, from time to time, receive from
            Uvik or its associates, communications such as service
            announcements, advertisements or administrative communications.
          </p>
          <p>
            All information provided by the User to Uvik shall be accurate,
            current and complete. User shall be solely responsible for the
            accuracy and correctness of all information provided by him. Uvik
            shall not be liable for any loss or costs incurred by any party due
            to any incorrect or mistaken information provided by User.
          </p>
          <p>
            Uvik shall not be liable for any direct, indirect, punitive,
            incidental, exemplary, special or consequential damages or any loss
            of profit, business, revenue, goodwill or anticipated savings or any
            damages whatsoever arising out of or in any way connected with the
            use of the services
          </p>
          <p>For any queries / details Users can contact:</p>
          <p>
            Uvik Technologies Private Limited, <br />
            #677, 1st Floor, <br />
            13th Cross Road, 27th Main Rd, <br />
            1st Sector, HSR Layout <br />
            Bengaluru, Karnataka 560102 <br />
            Or <br />
            Write to us at support@uvik.in <br />
            <ul>
              <li>
                Any dispute or new situation not covered in Terms and Conditions
                will be discussed and closed with mutual conscience
              </li>
              <li>
                Terms and condition subjected to review and modification without
                prior notice
              </li>
              <li>
                The Courts of Bangalore, Karnataka, alone shall have the
                jurisdiction to try and adjudicate all suits
              </li>
            </ul>
          </p>
          <h3>
            <strong>Annexure A: Banned items</strong>
          </h3>
          <p>
            1) Adult goods and services which includes pornography and other
            sexually suggestive materials (including literature, imagery and
            other media); escort or prostitution services.
          </p>
          <p>
            2) Alcohol which includes Alcohol or alcoholic beverages such as
            beer, liquor, wine, or champagne.
          </p>
          <p>3) Body parts which includes organs or other body parts.</p>
          <p>
            4) Bulk marketing tools which includes email lists, software, or
            other products enabling unsolicited email messages (spam).
          </p>
          <p>
            5) Cable descramblers and black boxes which includes devices
            intended to obtain cable and satellite signals for free.
          </p>
          <p>
            6) Child pornography which includes pornographic materials involving
            minors.
          </p>
          <p>
            7) Copyright unlocking devices which includes Mod chips or other
            devices designed to circumvent copyright protection. 8) Copyrighted
            media which includes unauthorized copies of books, music, movies,
            and other licensed or protected materials.
          </p>
          <p>
            8) Copyrighted software which includes unauthorized copies of
            software, video games and other licensed or protected materials,
            including OEM or bundled software.
          </p>
          <p>
            9) Counterfeit and unauthorized goods which includes replicas or
            imitations of designer goods; items without a celebrity endorsement
            that would normally require such an association, fake autographs,
            counterfeit stamps, and other potentially unauthorized goods.
          </p>
          <p>
            10) Drugs and drug paraphernalia which includes illegal drugs and
            drug accessories, including herbal drugs like salvia and magic
            mushrooms.
          </p>
          <p>
            11) Drug test circumvention aids which includes drug cleansing
            shakes, urine test additives, and related items.
          </p>
          <p>
            12) Endangered species which includes plants, animals or other
            organisms (including product derivatives) in danger of extinction.
          </p>
          <p>
            13) Gaming/gambling which includes lottery tickets, sports bets,
            memberships/ enrolment in online gambling sites, and related
            content.
          </p>
          <p>
            14) Government IDs or documents which includes fake IDs, passports,
            diplomas, and noble titles.
          </p>
          <p>
            15) Hacking and cracking materials which includes manuals, how-to
            guides, information, or equipment enabling illegal access to
            software, servers, watomites, or other protected property.
          </p>
          <p>
            16) Illegal goods which includes materials, products, or information
            promoting illegal goods or enabling illegal acts.
          </p>
          <p>
            17) Miracle cures which includes unsubstantiated cures, remedies or
            other items marketed as quick health fixes.
          </p>
          <p>
            18) Offensive goods which includes literature, products or other
            materials that: a) Defame or slander any person or groups of people
            based on race, ethnicity, national origin, religion, sex, or other
            factors b) Encourage or incite violent acts c) Promote intolerance
            or hatred.
          </p>
          <p>
            19) Offensive goods, crime which includes crime scene photos or
            items, such as personal belongings, associated with criminals.
          </p>
          <p>
            20) Prescription drugs or herbal drugs or any kind of online
            pharmacies which includes drugs or other products requiring a
            prescription by a licensed medical practitioner.
          </p>
          <p>
            21) Pyrotechnic devices and hazardous materials which includes
            fireworks and related goods; toxic, flammable, and radioactive
            materials and substances.
          </p>
          <p>
            22) Regulated goods which includes air bags; batteries containing
            mercury; Freon or similar substances/refrigerants,
            chemical/industrial solvents, government uniforms, car titles or
            logos, license plates, police badges and law enforcement equipment,
            lock-picking devices, pesticides; postage meters, recalled items,
            slot machines, surveillance equipment; goods regulated by government
            or other agency specifications.
          </p>
          <p>
            23) Securities, which includes stocks, bonds, or related financial
            products.
          </p>
          <p>
            24) Tobacco and cigarettes which includes cigarettes, cigars,
            chewing tobacco, and related products.
          </p>
          <p>
            25) Traffic devices which includes radar detectors/ jammers ,
            license plate covers, traffic signal changers, and related products.
          </p>
          <p>
            26) Weapons which includes firearms, ammunition, knives, brass
            knuckles, gun parts, and other armaments. 28) Wholesale currency
            which includes discounted currencies or currency exchanges.
          </p>
          <p>
            27) Live animals or hides/skins/teeth, nails and other parts etc of
            animals.
          </p>
          <p>28) Multi Level Marketing collection fees.</p>
          <p>29) Matrix sites or sites using a matrix scheme approach.</p>
          <p>30) Work-at-home information.</p>
          <p>31) Drop-shipped merchandise.</p>
          <p>
            32) Any product or service which is not in compliance with all
            applicable laws and regulations whether federal, state, local or
            international including the laws of India.
          </p>
          <p>
            33) The Merchant shall not sell, purchase, provide or exchange a
            cardholder’s name or MasterCard / Visa account number information in
            any form obtained by reason of a MasterCard/ Visa Card transaction
            to any third party other than its MasterCard/ Visa acquiring
            Member-Citrus Pay, or pursuant to a government /statutory or
            competent body’s request.
          </p>
          <p>
            34) Pyrotechnic devices, combustibles, corrosives and hazardous
            materials which includes explosives, fireworks and related goods;
            toxic, flammable, and radioactive materials and substances
          </p>
          <p>
            35) Regulated goods which includes air bags; batteries containing
            mercury; Freon or similar substances/refrigerants;
            chemical/industrial solvents; government uniforms; car titles;
            license plates; police badges and law enforcement equipment;
            lock-picking devices; pesticides; postage meters; recalled items;
            slot machines; surveillance equipment; goods regulated by government
            or other agency specifications
          </p>
        </div>
      </div>
    </Layout>
  );
}
